import styled from 'styled-components';
import { Link as DefLink } from 'gatsby';
import { motion } from 'framer-motion';

import { fontStyles } from '@/theme';

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 24px 0;
  @media (max-width: 849px) {
    grid-template-columns: 1fr;
    margin: 33px 0 0;
  }
`;

export const MessageWrap = styled(motion.div).attrs(() => ({
  animate: { height: 'auto' },
  transition: { duration: 0.3 },
}))`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 156px;
  margin: 24px 0;
  padding: 3px 8px;
  @media (max-width: 849px) {
    height: 189px;
    margin: 33px 0;
  }
  @media (max-width: 575px) {
    padding: 2px 5px;
  }
`;

export const Message = styled.p`
  ${fontStyles[30]}
  color: var(--color-black);
  @media (max-width: 849px) {
    ${fontStyles[24]}
  }
`;

export const MessageLink = styled.a`
  ${fontStyles[30]}
  color: var(--color-black);
  text-decoration-thickness: 1px;
  text-underline-offset: 1px;
  @media (max-width: 849px) {
    ${fontStyles[24]}
  }
`;

export const SubmitWrap = styled.div`
  display: grid;
  grid-template-columns: ${({ hideText }) => (hideText ? '1fr' : '1fr 1fr')};
  column-gap: 8px;
  align-items: center;
  border: solid var(--color-black);
  border-width: 1px 0;
  @media (max-width: 849px) {
    grid-template-columns: ${() => '1fr'};
  }
`;

export const Text = styled.p`
  ${({ hidden }) => hidden && 'display: none;'}
  ${fontStyles[12]}
  color: var(--color-black);
  padding-left: 8px;
  @media (max-width: 849px) {
    grid-row: 2/3;
    border-top: 1px solid var(--color-black);
    padding: 3px 8px;
  }
  @media (max-width: 575px) {
    padding: 2px 5px;
  }
`;

export const Link = styled(DefLink)`
  ${fontStyles[12]}
  color: var(--color-black);
`;

export const Button = styled.button`
  display: grid;
  grid-template-columns: 21px auto 1fr auto;
  column-gap: 4px;
  align-items: center;
  text-align: left;
  padding: 4px 8px 4px 4px;
  @media (max-width: 849px) {
    grid-template-columns: 39px auto 1fr auto;
    column-gap: 8px;
    height: 45px;
    padding: 0 8px;
  }
  @media (max-width: 575px) {
    column-gap: 5px;
    padding: 0 5px;
  }
`;

export const Span = styled.span`
  ${fontStyles[16]}
  color: var(--color-black);
  @media (max-width: 849px) {
    ${fontStyles[18]}
  }
`;

export const DotsWrap = styled.div`
  width: 21px;
  height: 21px;
  transition: width 0.5s ease-in;
  will-change: width;
  position: relative;
  @media (hover) and (min-width: 850px) {
    ${Button}:enabled:hover > & {
      width: 76px;
    }
  }
  @media (hover) and (max-width: 849px) {
    ${Button}:enabled:hover > & {
      width: 140px;
    }
  }
  @media (max-width: 849px) {
    width: 39px;
    height: 39px;
  }
`;

export const Dot = styled.div`
  width: 21px;
  height: 21px;
  border: 1px solid var(--color-blue);
  border-color: ${({ error }) => (error ? 'var(--color-red)' : 'var(--color-blue)')};
  border-radius: 50%;
  background-color: ${({ error }) => (error ? 'var(--color-red)' : 'var(--color-blue)')};
  position: ${({ fixed }) => (fixed ? 'static' : 'absolute')};
  top: 0;
  left: ${({ i }) => i && `calc(50% * ${i})`};
  transform: ${({ i }) => i && `translateX(calc(-50% * ${i}))`};
  transition-property: border-color, background-color;
  transition-duration: 0.3s;
  @media (hover) {
    &:hover {
      border-color: var(--color-black);
      background-color: var(--color-black);
    }
    ${Button}:enabled:active & {
      border: 1px solid var(--color-black);
      background-color: var(--color-white);
    }
  }
  @media (max-width: 849px) {
    width: 39px;
    height: 39px;
  }
`;
