/* eslint-disable react/no-array-index-key */
import React, { Fragment } from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Form from './From';
import Link from './Link';
import { Text, A } from './styles';

const Contact = () => {
  const { contentJson } = useStaticQuery(graphql`
    {
      contentJson {
        contact {
          items {
            id
            linkText
            link
            texts {
              type
              text
              name
              href
            }
            inputs {
              type
              key
              label
            }
            submit {
              button
              texts {
                type
                text
                name
                to
              }
              backButton
              successMessage {
                type
                text
              }
              errorMessage {
                type
                text
                name
                href
              }
            }
          }
        }
      }
    }
  `);
  const { items } = contentJson.contact;

  return items.map(({ id, texts, inputs, submit, linkText, link }) => (
    <Fragment key={id}>
      {id === 'texts' && (
        <Text>
          {texts.map(({ type, text: t, name, href }, index) => (
            <Fragment key={index}>
              {type === 'text' && (t || '')}
              {type === 'link' && (
                <A href={href} rel="noopener noreferrer" target="_blank">
                  {name}
                </A>
              )}
            </Fragment>
          ))}
        </Text>
      )}
      {id === 'textWithDots' && <Link text={linkText} href={link} />}
      {id === 'form' && (
        <Form
          inputs={inputs}
          buttonName={submit.button}
          texts={submit.texts}
          backButtonName={submit.backButton}
          successMessage={submit.successMessage}
          errorMessage={submit.errorMessage}
        />
      )}
    </Fragment>
  ));
};

export default Contact;
