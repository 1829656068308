import { object, string } from 'yup';

export const validation = object().shape({
  name: string().required('Name fehlt'),
  email: string().email('E-Mail-Adresse ungültig').required('E-Mail-Adresse fehlt'),
  message: string().required('Nachricht fehlt'),
});

export const extractErrors = ({ inner = [] }) =>
  inner.reduce((acc, { path, message }) => {
    acc[path] = message;

    return acc;
  }, {});
