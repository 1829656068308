/* eslint-disable react/no-array-index-key */
import React, { useCallback, Fragment } from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import { threeDots } from '@/utils';
import Arrow from '@/components/Arrow';

import Item from './Item';
import { Text, Ul, Li, A, LinkText, Link, LinkDotsWrap, LinkDot } from './styles';

const Faq = () => {
  const { contentJson } = useStaticQuery(graphql`
    {
      contentJson {
        faq {
          items {
            id
            title
            text
            list
            texts {
              type
              text
              name
              href
            }
          }
          extraContent {
            text
            to
          }
        }
      }
    }
  `);
  const { items, extraContent } = contentJson.faq;

  const preventBubbling = useCallback((e) => {
    e.stopPropagation();
  }, []);

  return (
    <>
      {items.map(({ id, title, text, list, texts }) => (
        <Item key={id} id={id} title={title}>
          {text && <Text>{text}</Text>}
          {list && (
            <Ul>
              {list.map((item) => (
                <Li key={item}>{item}</Li>
              ))}
            </Ul>
          )}
          {texts && (
            <Text>
              {texts.map(({ type, text: t, name, href }, index) => (
                <Fragment key={index}>
                  {type === 'text' && (t || '')}
                  {type === 'link' && (
                    <A href={href} onClick={preventBubbling} rel="noopener noreferrer" target="_blank">
                      {name}
                    </A>
                  )}
                </Fragment>
              ))}
            </Text>
          )}
        </Item>
      ))}
      <Link to={extraContent.to}>
        <LinkText>{extraContent.text}</LinkText>
        <Arrow right />
        <LinkDotsWrap>
          {threeDots.map((i) => (
            <LinkDot key={i} i={i} />
          ))}
        </LinkDotsWrap>
      </Link>
    </>
  );
};

export default Faq;
