import styled from 'styled-components';
import { motion } from 'framer-motion';

import { fontStyles } from '@/theme';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  border-bottom: 1px solid var(--color-black);
  padding: 5px 8px;
  @media (max-width: 575px) {
    padding: 5px;
  }
`;

export const Wrapper = styled.div`
  height: 57px;
  position: relative;
  overflow: hidden;
  @media (max-width: 849px) {
    height: 39px;
    margin-left: 0;
  }
`;

export const AnimatedBox = styled(motion.div).attrs(({ duration }) => ({
  animate: { translateX: '-100%', left: 0 },
  transition: { duration, ease: 'linear' },
  initial: { translateX: 0, left: '100%' },
}))`
  display: flex;
  align-items: center;
  height: 100%;
  width: max-content;
  position: absolute;
  left: 100%;
`;

export const Text = styled.p`
  display: inline;
  ${fontStyles[30]}
  color: var(--color-black);
  margin-right: 2em;
  @media (max-width: 849px) {
    ${fontStyles[16]}
  }
`;

export const Span = styled.span`
  ${fontStyles[24]}
  color: inherit;
  @media (max-width: 849px) {
    ${fontStyles[18]}
  }
`;
