import React, { useMemo, Fragment } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';

import { threeDots } from '@/utils';
import Anchor from '@/components/PageAnchor';
import App from '@/components/App';
import Faq from '@/components/Faq';
import Contact from '@/components/Contact';
import Footer from '@/components/Footer';
import Arrow from '@/components/Arrow';

import { Container, Wrapper, Title, Link, ArrowWrap, DotsWrap, Dot } from './styles';

const Index = ({ location }) => {
  const { contentJson } = useStaticQuery(graphql`
    {
      contentJson {
        app {
          anchor
          title
        }
        faq {
          anchor
          title
        }
        contact {
          anchor
          title
        }
        footer {
          anchor
        }
      }
    }
  `);
  const items = useMemo(() => Object.entries(contentJson), [contentJson]);

  return (
    <Container>
      {items.map(([key, { anchor, title }]) => (
        <Fragment key={key}>
          <Anchor id={anchor} hash={location.hash} />
          <Wrapper>
            {title && <Title>{title}</Title>}
            {key === 'app' && <App />}
            {key === 'faq' && <Faq />}
            {key === 'contact' && <Contact />}
            {key === 'footer' && <Footer />}
          </Wrapper>
        </Fragment>
      ))}
      <Link to="/" aria-label="Up">
        <ArrowWrap>
          <Arrow left />
        </ArrowWrap>
        <DotsWrap>
          {threeDots.map((i) => (
            <Dot key={i} i={i} />
          ))}
        </DotsWrap>
      </Link>
    </Container>
  );
};

Index.propTypes = {
  location: PropTypes.shape({
    hash: PropTypes.string.isRequired,
  }).isRequired,
};

export default Index;
