import styled from 'styled-components';

import { fontStyles } from '@/theme';

export const Text = styled.p`
  ${fontStyles[30]}
  color: var(--color-black);
  border-top: 1px solid var(--color-black);
  padding: 3px 10px;
  @media (max-width: 575px) {
    ${fontStyles[24]}
    padding: 2px 5px 5px;
  }
`;

export const A = styled.a`
  ${fontStyles[30]}
  color: var(--color-black);
  text-decoration-thickness: 1px;
  text-underline-offset: 1px;
  @media (max-width: 849px) {
    ${fontStyles[24]}
  }
`;
