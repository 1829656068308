import styled from 'styled-components';

export const Container = styled.div`
  height: 10px;
  width: 100%;
  position: relative;
`;

export const Line = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  height: 1px;
  width: 100%;
  background-color: var(--color-black);
`;

export const Wedge = styled.div`
  position: absolute;
  height: 100%;
  ${({ $left }) => $left && 'left: 0;'}
  ${({ $right }) => $right && 'right: 10px;'}
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 10px;
    height: 1px;
    background-color: var(--color-black);
    transform: rotate(-25deg);
    transform-origin: ${({ $left, $right }) => ($left && 'top left') || ($right && 'bottom right')};
  }
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 10px;
    height: 1px;
    background-color: var(--color-black);
    transform: rotate(25deg);
    transform-origin: ${({ $left, $right }) => ($left && 'top left') || ($right && 'bottom right')};
  }
`;
