import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import { Button, Content } from './styles';

const AnimateBox = ({ title, index, children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleClick = useCallback(() => {
    if (window.innerWidth <= 720) setIsOpen(($) => !$);
  }, []);

  return (
    <>
      <Button type="button" onClick={handleClick} mt={index > 0}>
        {title}
      </Button>
      <Content isOpen={isOpen}>{children}</Content>
    </>
  );
};

AnimateBox.propTypes = {
  title: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
};

export default AnimateBox;
