/* eslint-disable react/no-array-index-key */
import React, { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';

import Packman from './Packman';

import { Container, Wrapper, AnimatedBox, Text, Span } from './styles';

const ReviewList = ({ list }) => {
  const [key, setKey] = useState(0);
  const [items, duration] = useMemo(() => {
    const factor = 80;
    const singleString = list.map((item) => item.split('##sender##'));
    const wholeString = new Array(factor).fill(singleString).reduce((acc, item) => [...acc, ...item]);

    let symbols = 0;
    singleString.forEach((i) => {
      i.forEach((w) => {
        symbols += w.length;
      });
    });

    return [wholeString, (symbols / 920) * 60 * factor];
  }, [list]);

  const handleComplete = useCallback(() => setKey(($) => $ + 1), []);

  return (
    <Container>
      <Wrapper>
        <AnimatedBox key={key} duration={duration} onAnimationComplete={handleComplete}>
          {items.map((item, index) => (
            <Text key={index}>
              {item[0]}
              <Span>{item[1]}</Span>
            </Text>
          ))}
        </AnimatedBox>
        <Packman left />
        <Packman right />
      </Wrapper>
    </Container>
  );
};

ReviewList.propTypes = {
  list: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
};

export default ReviewList;
