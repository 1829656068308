import styled from 'styled-components';

import { fontStyles } from '@/theme';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 140px 1fr;
  width: 100%;
  position: relative;
`;

export const TextInput = styled.input`
  width: 100%;
  height: 28px;
  border: 1px solid var(--color-blue);
  border-color: ${({ error, value }) => (error && 'var(--color-red)') || (value && 'var(--color-black)') || 'var(--color-blue)'};
  ${({ error, value }) => !(error || value) && 'border-bottom: 1px solid var(--color-white);'}
  background-color: ${({ error, value }) => (error || value ? 'var(--color-white)' : 'var(--color-blue)')};
  ${fontStyles[16]}
  color: var(--color-black);
  padding: 0 8px;
  transition-property: border-color, background-color;
  transition-duration: 0.3s;
  @media (hover) {
    &:hover {
      ${({ error, value }) =>
        !(error || value) &&
        `
        border-color: var(--color-black);
        background-color: var(--color-black);
      `}
    }
  }
  &:focus {
    border-color: ${({ error }) => (error ? 'var(--color-red)' : 'var(--color-black)')};
    background-color: var(--color-white);
  }
  @media (max-width: 849px) {
    height: 33px;
  }
`;

export const Textarea = styled.textarea`
  width: 100%;
  height: 156px;
  border: 1px solid var(--color-blue);
  border-color: ${({ error, value }) => (error && 'var(--color-red)') || (value && 'var(--color-black)') || 'var(--color-blue)'};
  background-color: ${({ error, value }) => (error || value ? 'var(--color-white)' : 'var(--color-blue)')};
  ${fontStyles[16]}
  color: var(--color-black);
  padding: 3px 8px 8px;
  resize: none;
  transition-property: border-color, background-color;
  transition-duration: 0.3s;
  @media (hover) {
    &:hover {
      ${({ error, value }) =>
        !(error || value) &&
        `
        border-color: var(--color-black);
        background-color: var(--color-black);
      `}
    }
  }
  &:focus {
    border-color: ${({ error }) => (error ? 'var(--color-red)' : 'var(--color-black)')};
    background-color: var(--color-white);
  }
`;

export const Label = styled.label`
  display: flex;
  align-items: center;
  height: 28px;
  border: 1px solid var(--color-black);
  border-width: ${({ type, index }) => (type === 'input' && index > 0 ? '0 0 1px 0' : '1px 0')};
  ${fontStyles[16]}
  color: var(--color-black);
  padding: 0 8px;
  @media (max-width: 849px) {
    height: 33px;
    border-width: ${({ index }) => (index > 0 ? '0 0 1px 0' : '1px 0')};
  }
  @media (max-width: 575px) {
    padding: 0 5px;
  }
`;

export const Dot = styled.div`
  width: 21px;
  height: 21px;
  border: 1px solid;
  border-color: ${({ filled }) => (filled ? 'var(--color-black)' : 'var(--color-blue)')};
  border-radius: 50%;
  background-color: ${({ filled }) => (filled ? 'var(--color-white)' : 'var(--color-blue)')};
  margin-right: 3px;
  transition-property: border-color, background-color;
  transition-duration: 0.3s;
  @media (hover) {
    &:hover {
      border-color: var(--color-black);
      background-color: ${({ filled }) => (filled ? 'var(--color-white)' : 'var(--color-black)')};
    }
  }
  @media (max-width: 849px) {
    width: 24px;
    height: 24px;
  }
`;

export const Message = styled.p`
  position: absolute;
  top: 2px;
  right: 5px;
  ${fontStyles[12]}
  color: var(--color-red);
`;
