import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Container = styled.div`
  position: absolute;
  top: 0;
  ${({ $left, $right }) =>
    `${($left && 'left: 0;') || ($right && 'right: 0;')} background: linear-gradient(${
      $left ? 90 : 270
    }deg, var(--color-white) 0% 50%, transparent 50% 100%);`};
`;

export const Head = styled.div`
  width: 57px;
  height: 57px;
  position: relative;
  @media (max-width: 849px) {
    width: 39px;
    height: 39px;
  }
`;

const sideCommonStyles = `
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
`;

export const SideTop = styled(motion.div).attrs(({ $right }) => {
  if ($right)
    return {
      animate: { rotate: -40 },
      transition: { repeat: Infinity, repeatType: 'mirror', duration: 0.25 },
      initial: { rotate: -1 },
    };

  return {};
})`
  ${sideCommonStyles}
  background: linear-gradient(0deg, var(--color-blue) 0% 50%, transparent 50% 100%);
  @media (hover) {
    ${Head}:hover > & {
      background: linear-gradient(0deg, var(--color-black) 0% 50%, transparent 50% 100%);
    }
  }
`;

export const SideBottom = styled(motion.div).attrs(({ $right }) => {
  if ($right)
    return {
      animate: { rotate: 40 },
      transition: { repeat: Infinity, repeatType: 'mirror', duration: 0.25 },
      initial: { rotate: 1 },
    };

  return {};
})`
  ${sideCommonStyles}
  background: linear-gradient(180deg, var(--color-blue) 0% 50%, transparent 50% 100%);
  @media (hover) {
    ${Head}:hover > & {
      background: linear-gradient(180deg, var(--color-black) 0% 50%, transparent 50% 100%);
    }
  }
`;
