/* eslint-disable react/no-array-index-key */
import React, { useMemo, Fragment } from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import AnimateBox from './AnimateBox';
import { Container, Wrapper, P, A, Link, ListTitle, ListDot, DoubleContent } from './styles';

const Footer = () => {
  const { contentJson } = useStaticQuery(graphql`
    {
      contentJson {
        footer {
          policy {
            title
            preamble {
              textBefore
              doubleText {
                type
                text
                name
                href
              }
            }
            list {
              name
              text
              texts
            }
          }
          agreement {
            title
            preamble {
              textBefore
            }
            list {
              name
              text
              textWithLink {
                type
                text
                name
                to
              }
              texts
              textsWithLinks {
                type
                text
                name
                href
              }
            }
          }
          imprint {
            title
            preamble {
              doubleText {
                type
                text
                name
                href
              }
            }
            list {
              text
            }
          }
        }
      }
    }
  `);
  const { policy, agreement, imprint } = contentJson.footer;
  const contents = useMemo(() => [policy, agreement, imprint], [policy, agreement, imprint]);

  return (
    <Container>
      {contents.map(({ title, preamble, list }, index) => (
        <AnimateBox key={index} title={title} index={index}>
          <Wrapper>
            {preamble.textBefore && <P>{preamble.textBefore}</P>}
            {index === 0 && <P />}
            {preamble.doubleText && (
              <DoubleContent>
                {preamble.doubleText.map(({ type, text, href, name }, key) => (
                  <Fragment key={key}>
                    {type === 'textBefore' && <P>{text}</P>}
                    {type === 'text' && <P>{text}</P>}
                    {type === 'link' && (
                      <A href={href} rel="noopener noreferrer" target="_blank">
                        {name}
                      </A>
                    )}
                  </Fragment>
                ))}
              </DoubleContent>
            )}
            {index === 2 && <P />}
            {list.map(({ name, text, texts, textWithLink, textsWithLinks }, key) => (
              <Fragment key={key}>
                {name && (
                  <ListTitle>
                    <ListDot />
                    <P>{name}</P>
                  </ListTitle>
                )}
                {text && <P>{text}</P>}
                {texts &&
                  texts.map((t, i) => (
                    <P key={i} isIndent={i > 0}>
                      {t}
                    </P>
                  ))}
                {textWithLink && (
                  <P>
                    {textWithLink.map(({ type, text: t, to, name: n }, i) => (
                      <Fragment key={i}>
                        {type === 'text' && t}
                        {type === 'link' && <Link to={to}>{n}</Link>}
                      </Fragment>
                    ))}
                  </P>
                )}
                <DoubleContent>
                  {textsWithLinks &&
                    textsWithLinks.map(({ type, text: t, href, name: n }, i) => (
                      <Fragment key={i}>
                        {type === 'text' && <P>{t}</P>}
                        {type === 'link' && (
                          <A href={href} rel="noopener noreferrer" target="_blank">
                            {n}
                          </A>
                        )}
                      </Fragment>
                    ))}
                </DoubleContent>
              </Fragment>
            ))}
          </Wrapper>
        </AnimateBox>
      ))}
    </Container>
  );
};
export default Footer;
