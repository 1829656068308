import styled from 'styled-components';
import { Link as DefLink } from 'gatsby';

import { fontStyles } from '@/theme';

export const Container = styled.div`
  margin: 10px 0;
  column-count: 3;
  column-gap: 8px;
  @media (max-width: 1070px) {
    column-count: 2;
  }
  @media (max-width: 720px) {
    column-count: 1;
  }
`;

export const Wrapper = styled.div`
  padding: 2px 8px;
  @media (max-width: 575px) {
    padding: 2px 5px;
  }
`;

export const P = styled.p`
  min-height: 12px;
  ${fontStyles[12]}
  color: var(--color-black);
  text-indent: ${({ isIndent }) => (isIndent ? 1 : 0)}em;
  @media (max-width: 575px) {
    min-height: 13px;
    ${fontStyles[13]}
  }
`;

export const A = styled.a`
  display: block;
  width: fit-content;
  ${fontStyles[12]}
  color: var(--color-black);
  @media (max-width: 575px) {
    ${fontStyles[13]}
  }
`;

export const Link = styled(DefLink)`
  ${fontStyles[12]}
  color: var(--color-black);
  @media (max-width: 575px) {
    ${fontStyles[13]}
  }
`;

export const ListTitle = styled.div`
  display: flex;
  break-inside: avoid;
  padding-top: 12px;
  @media (max-width: 575px) {
    padding-top: 13px;
  }
`;

export const ListDot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--color-black);
  margin: 1px 0.1em 0 0;
  @media (max-width: 575px) {
    width: 12px;
    height: 12px;
    margin: 0 0.1em 0 0;
  }
`;

export const DoubleContent = styled.div`
  column-count: 2;
  overflow: hidden;
  @media (max-width: 385px) {
    column-count: 1;
  }
`;
