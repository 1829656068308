import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import { threeDots } from '@/utils';

import { Container, Digit, Box, Triangle, DotWrap, Dot, Title, Wrapper } from './styles';

const Item = ({ id, title, children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleClick = useCallback(() => setIsOpen(($) => !$), []);

  return (
    <Container type="button" onClick={handleClick}>
      <Digit>{id}</Digit>
      <Box>
        <Triangle $isOpen={isOpen} $left />
        <Triangle $isOpen={isOpen} $right />
        <DotWrap $isOpen={isOpen}>
          {threeDots.map((i) => (
            <Dot key={i} $i={i} $isOpen={isOpen} />
          ))}
        </DotWrap>
      </Box>
      <Title $isOpen={isOpen}>{title}</Title>
      <Wrapper $isOpen={isOpen}>{children}</Wrapper>
      <Digit>{id}</Digit>
    </Container>
  );
};

Item.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default Item;
