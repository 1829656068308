import styled from 'styled-components';

import { fontStyles } from '@/theme';

export const Container = styled.a`
  min-height: 42px;
  display: grid;
  grid-template-columns: minmax(auto, 460px) 1fr auto;
  column-gap: 4px;
  align-items: center;
  border: solid var(--color-black);
  border-width: 1px 0;
  padding: 2px 10px;
  text-decoration: none;
  @media (max-width: 849px) {
    min-height: 60px;
    grid-template-columns: minmax(auto, 520px) 1fr auto;
  }
  @media (max-width: 650px) {
    grid-template-columns: 9fr 1fr auto;
  }
  @media (max-width: 575px) {
    padding: 2px 5px;
  }
`;

export const LinkText = styled.p`
  ${fontStyles[16]}
  color: var(--color-black);
  @media (max-width: 849px) {
    ${fontStyles[18]}
  }
`;

export const HighlightLinkText = styled.span`
  ${fontStyles[16]}
  color: var(--color-black);
  text-decoration: underline;
  @media (max-width: 849px) {
    ${fontStyles[18]}
  }
`;

export const DotsWrap = styled.div`
  width: 35px;
  height: 35px;
  transition: width 0.5s ease-in;
  will-change: width;
  position: relative;
  @media (hover) and (min-width: 850px) {
    ${Container}:hover > & {
      width: 125px;
    }
  }
  @media (max-width: 849px) {
    width: 56px;
    height: 56px;
  }
`;

export const Dot = styled.div`
  width: 35px;
  height: 35px;
  border: 1px solid var(--color-blue);
  border-radius: 50%;
  background-color: var(--color-blue);
  position: absolute;
  top: 0;
  left: ${({ i }) => i && `calc(50% * ${i})`};
  transform: ${({ i }) => i && `translateX(calc(-50% * ${i}))`};
  transition-property: border-color, background-color;
  transition-duration: 0.3s;
  @media (hover) {
    &:hover {
      border-color: var(--color-black);
      background-color: var(--color-black);
    }
    ${Container}:active & {
      border: 1px solid var(--color-black);
      background-color: var(--color-white);
    }
  }
  @media (max-width: 849px) {
    width: 56px;
    height: 56px;
  }
`;
