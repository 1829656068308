import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';

import { Container, Label, Dot, TextInput, Textarea, Message } from './styles';

const Input = ({ index, type, name, label, value, onChange, error, disabled }) => {
  const randomId = useMemo(() => `${type}-${String(Math.random()).slice(2)}`, [type]);
  const handleChange = useCallback(
    ({ currentTarget }) => onChange(($) => ({ ...$, [currentTarget.name]: currentTarget.value })),
    [onChange]
  );

  return (
    <Container>
      <Label htmlFor={randomId} type={type} index={index}>
        <Dot filled={Boolean(value)} />
        {label}
      </Label>
      {type === 'input' && (
        <TextInput
          id={randomId}
          type="text"
          name={name}
          value={value}
          onChange={handleChange}
          error={error}
          disabled={disabled}
        />
      )}
      {type === 'textarea' && (
        <Textarea id={randomId} name={name} value={value} onChange={handleChange} error={error} disabled={disabled} />
      )}
      {error && <Message>{error}</Message>}
    </Container>
  );
};

Input.defaultProps = { value: '', error: null, disabled: false };
Input.propTypes = {
  index: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
  disabled: PropTypes.bool,
};

export default Input;
