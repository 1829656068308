import styled from 'styled-components';
import { Link as DefLink } from 'gatsby';

import { fontStyles } from '@/theme';

export const Container = styled.div`
  margin-top: 66px;
  @media (max-width: 575px) {
    margin-top: 33px;
  }
`;

export const Wrapper = styled.div`
  border-top: 1px solid var(--color-black);
  margin-bottom: 80px;
  &:last-of-type {
    margin-bottom: 0px;
  }
  @media (max-width: 575px) {
    margin-bottom: 65px;
    &:last-of-type {
      margin-bottom: 0px;
    }
  }
`;

export const Title = styled.h1`
  ${fontStyles[277]}
  color: var(--color-black);
  margin: -0.18em 0.05em 0;
  @media (max-width: 1500px) {
    font-size: calc(100vw * 277 / 1500);
    letter-spacing: calc(-100vw * 15 / 1500);
  }
  @media (max-width: 350px) {
    font-size: 65px;
  }
`;

export const Link = styled(DefLink)`
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 60px auto;
  row-gap: 5px;
  justify-content: center;
  align-items: center;
  width: 24px;
  margin: 0 auto 66px;
`;

export const ArrowWrap = styled.div`
  width: 61px;
  height: 11px;
  transform: rotate(90deg);
  transform-origin: center;
`;

export const DotsWrap = styled.div`
  width: 21px;
  height: 21px;
  transition: height 0.5s ease-in;
  will-change: height;
  position: relative;
  margin: auto;
  @media (hover) {
    ${Link}:hover > & {
      height: 81px;
    }
  }
  @media (max-width: 849px) {
    width: 24px;
    height: 24px;
  }
  @media (hover) and (max-width: 849px) {
    ${Link}:hover > & {
      height: 87px;
    }
  }
`;

export const Dot = styled.div`
  width: 21px;
  height: 21px;
  border: 1px solid var(--color-blue);
  border-radius: 50%;
  background-color: var(--color-blue);
  position: absolute;
  left: 0;
  top: ${({ i }) => i && `calc(50% * ${i})`};
  transform: ${({ i }) => i && `translateY(calc(-50% * ${i}))`};
  transition-property: border-color, background-color;
  transition-duration: 0.3s;
  @media (hover) {
    &:hover {
      border-color: var(--color-black);
      background-color: var(--color-black);
    }
    ${Link}:active & {
      border: 1px solid var(--color-black);
      background-color: var(--color-white);
    }
  }
  @media (max-width: 849px) {
    width: 24px;
    height: 24px;
  }
`;
