import React, { useCallback, Fragment } from 'react';
import PropTypes from 'prop-types';

import { useAsyncState, threeDots } from '@/utils';
import Arrow from '@/components/Arrow';

import { useSubmitForm } from './hooks';
import { validation, extractErrors } from './utils';
import Input from './Input';
import { Wrapper, MessageWrap, Message, MessageLink, SubmitWrap, Text, Link, Button, Span, Dot, DotsWrap } from './styles';

const Form = ({ inputs, buttonName, texts, backButtonName, successMessage, errorMessage }) => {
  const [data, setData] = useAsyncState({});
  const [errors, setErrors] = useAsyncState({});

  const [{ loading, success, error }, action, reset] = useSubmitForm(successMessage, errorMessage);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      if (error) {
        reset();

        return;
      }
      if (success) {
        reset();
        setData({});

        return;
      }

      try {
        const values = await validation.validate(data, { abortEarly: false });
        action(values);
      } catch (validateError) {
        setErrors(extractErrors(validateError));
      }
    },
    [action, data, error, reset, setData, setErrors, success]
  );

  const resetErrors = useCallback(
    ({ target }) => {
      if (loading) return;

      setErrors(($) => ({ ...$, [target.name]: '' }));
    },
    [loading, setErrors]
  );

  return (
    <form onSubmit={handleSubmit} onChange={resetErrors}>
      {success || error ? (
        <MessageWrap>
          <Message>
            {(success || error).map(({ type, text, name, href }) => (
              <Fragment key={text || name}>
                {type === 'text' && text}
                {type === 'link' && (
                  <MessageLink href={href} rel="noopener noreferrer" target="_blank">
                    {name}
                  </MessageLink>
                )}
              </Fragment>
            ))}
          </Message>
        </MessageWrap>
      ) : (
        <Wrapper>
          <div>
            {inputs.map(
              ({ type, key, label }, index) =>
                type === 'input' && (
                  <Input
                    key={key}
                    index={index}
                    name={key}
                    type="input"
                    label={label}
                    value={data[key]}
                    onChange={setData}
                    disabled={loading}
                    error={errors[key]}
                  />
                )
            )}
          </div>
          <div>
            {inputs.map(
              ({ type, key, label }, index) =>
                type === 'textarea' && (
                  <Input
                    key={key}
                    index={index}
                    name={key}
                    type="textarea"
                    label={label}
                    value={data[key]}
                    onChange={setData}
                    disabled={loading}
                    error={errors[key]}
                  />
                )
            )}
          </div>
        </Wrapper>
      )}
      <SubmitWrap hideText={Boolean(error || success)}>
        <Text hidden={Boolean(error || success)}>
          {texts.map(({ type, text, name, to }) => (
            <Fragment key={text || name}>
              {type === 'text' && (text || '')}
              {type === 'link' && <Link to={to}>{name}</Link>}
            </Fragment>
          ))}
        </Text>
        <Button type="submit" disabled={loading}>
          <Dot fixed error={Boolean(error)} />
          <Span>{success || error ? backButtonName : buttonName}</Span>
          <Arrow right />
          <DotsWrap>
            {threeDots.map((i) => (
              <Dot key={i} i={i} error={Boolean(error)} />
            ))}
          </DotsWrap>
        </Button>
      </SubmitWrap>
    </form>
  );
};

Form.propTypes = {
  inputs: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  buttonName: PropTypes.string.isRequired,
  texts: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      name: PropTypes.string,
      to: PropTypes.string,
      text: PropTypes.string,
    }).isRequired
  ).isRequired,
  backButtonName: PropTypes.string.isRequired,
  successMessage: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  errorMessage: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      name: PropTypes.string,
      href: PropTypes.string,
      text: PropTypes.string,
    }).isRequired
  ).isRequired,
};

export default Form;
