import styled from 'styled-components';
import { motion } from 'framer-motion';

import { fontStyles } from '@/theme';

export const Container = styled.button`
  display: grid;
  grid-template-columns: 28px 1fr 1fr 28px;
  grid-template-rows: minmax(28px, auto) auto;
  width: 100%;
  border-top: 1px solid var(--color-black);
  text-align: left;
  @media (max-width: 849px) {
    grid-template-columns: 32px 1fr 32px;
  }
`;

export const Digit = styled.div`
  grid-row: 1/-1;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  ${fontStyles[16]}
  line-height: 28px;
  color: var(--color-black);
  &:first-of-type {
    grid-column: 1/2;
    border-right: 1px solid var(--color-black);
  }
  &:last-of-type {
    grid-column: -2/-1;
    border-left: 1px solid var(--color-black);
  }
  @media (max-width: 849px) {
    grid-row: 1/2;
    ${fontStyles[18]}
    line-height: 32px;
  }
`;

export const Box = styled.div`
  grid-column: 2/3;
  grid-row: 1/-1;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  height: 100%;
  border-right: 1px solid var(--color-black);
  padding: 3px 0;
  overflow: hidden;
  position: relative;
  @media (max-width: 849px) {
    display: none;
  }
`;

export const Triangle = styled(motion.div).attrs(({ $isOpen }) => ({
  animate: { opacity: $isOpen ? [1, 0, 0.9999999] : [0.9999999, 0, 1] },
  transition: { duration: 0.5, times: [0, 0, 1] },
  initial: { opacity: 1 },
}))`
  width: 50%;
  position: absolute;
  top: 0;
  bottom: 0;
  ${({ $left, $right }) => `${($left && 'left: 0;') || ($right && 'right: 0;')}`};
  background: ${({ $left, $right, isOpen }) => {
    if ($left && !isOpen)
      return 'linear-gradient(to top right, var(--color-blue) 0% calc(50% - 0.5px), transparent calc(50% + 0.5px) 100%)';
    if ($right && !isOpen)
      return 'linear-gradient(to top left, var(--color-blue) 0% calc(50% - 0.5px), transparent calc(50% + 0.5px) 100%)';
    if ($left && isOpen)
      return 'linear-gradient(to bottom right, transparent 0% calc(50% - 0.5px), var(--color-black) calc(50% - 0.5px) calc(50% + 0.5px), transparent calc(50% + 0.5px) 100%)';
    if ($right && isOpen)
      return 'linear-gradient(to bottom left, transparent 0% calc(50% - 0.5px), var(--color-black) calc(50% - 0.5px) calc(50% + 0.5px), transparent calc(50% + 0.5px) 100%)';

    return 'none';
  }};
  @media (hover) {
    &:hover {
      background: ${({ $left, $right, isOpen }) => {
        if ($left && !isOpen)
          return 'linear-gradient(to top right, var(--color-black) 0% calc(50% - 0.5px), transparent calc(50% + 0.5px) 100%)';
        if ($right && !isOpen)
          return 'linear-gradient(to top left, var(--color-black) 0% calc(50% - 0.5px), transparent calc(50% + 0.5px) 100%)';
        if ($left && isOpen)
          return 'linear-gradient(to bottom right, transparent 0% calc(50% - 0.5px), var(--color-black) calc(50% - 0.5px) calc(50% + 0.5px), transparent calc(50% + 0.5px) 100%)';
        if ($right && isOpen)
          return 'linear-gradient(to bottom left, transparent 0% calc(50% - 0.5px), var(--color-black) calc(50% - 0.5px) calc(50% + 0.5px), transparent calc(50% + 0.5px) 100%)';

        return 'none';
      }};
    }
  }
`;

export const DotWrap = styled(motion.div).attrs(({ $isOpen }) => ({
  animate: { height: $isOpen ? '97%' : 21 },
  transition: { duration: $isOpen ? 0 : 0.5 },
  initial: { height: 21 },
}))`
  width: 21px;
  position: relative;
  bottom: 0;
  @media (max-width: 849px) {
    display: none;
  }
`;

export const Dot = styled.div`
  width: 21px;
  height: 21px;
  border: 1px solid var(--color-blue);
  border-color: ${({ $isOpen }) => ($isOpen ? 'var(--color-black)' : 'var(--color-blue)')};
  border-radius: 50%;
  background-color: ${({ $isOpen }) => ($isOpen ? 'var(--color-white)' : 'var(--color-blue)')};
  position: absolute;
  left: 0;
  top: ${({ $i }) => $i && `calc(50% * ${$i})`};
  transform: ${({ $i }) => $i && `translateY(calc(-50% * ${$i}))`};
  transition-property: border-color, background-color;
  transition-duration: 0.3s;
  @media (hover) {
    &:hover {
      border-color: var(--color-black);
      background-color: ${({ $isOpen }) => ($isOpen ? 'var(--color-white)' : 'var(--color-black)')};
    }
  }
  @media (max-width: 849px) {
    display: none;
  }
`;

export const Title = styled.div`
  grid-column: 3/4;
  grid-row: 1/2;
  display: flex;
  column-gap: 3px;
  align-items: center;
  height: 100%;
  ${fontStyles[16]}
  color: var(--color-black);
  padding: 0 8px;
  &::before {
    content: '';
    display: block;
    width: 21px;
    min-width: 21px;
    height: 21px;
    box-sizing: border-box;
    border: 1px solid;
    border-color: ${({ $isOpen }) => ($isOpen ? 'var(--color-black)' : 'var(--color-blue)')};
    border-radius: 50%;
    background-color: ${({ $isOpen }) => ($isOpen ? 'var(--color-white)' : 'var(--color-blue)')};
    transition-property: border-color, background-color;
    transition-duration: 0.3s;
  }
  @media (hover) {
    &:hover {
      &::before {
        border-color: var(--color-black);
        background-color: ${({ isOpen }) => (isOpen ? 'var(--color-white)' : 'var(--color-black)')};
      }
    }
  }
  @media (max-width: 849px) {
    grid-column: 2/3;
    min-height: 32px;
    ${fontStyles[18]}
    &::before {
      display: none;
    }
  }
  @media (max-width: 575px) {
    padding: 0 5px;
  }
`;

export const Wrapper = styled(motion.div).attrs(({ $isOpen }) => ({
  animate: { height: $isOpen ? 'auto' : 0 },
  transition: { duration: 0.5 },
  initial: { height: 0 },
}))`
  grid-column: 3/4;
  grid-row: 2/3;
  overflow: hidden;
  @media (max-width: 849px) {
    grid-column: 1/-1;
  }
`;
