import styled from 'styled-components';
import { Link as DefLink } from 'gatsby';

import { fontStyles } from '@/theme';

export const Text = styled.p`
  ${fontStyles[16]}
  color: var(--color-black);
  border-top: 1px solid var(--color-black);
  padding: 3px 8px 8px;
  @media (max-width: 575px) {
    ${fontStyles[18]}
    padding: 2px 5px 5px;
  }
`;

export const Ul = styled.ul`
  border-top: 1px solid var(--color-black);
  padding: 3px 8px 8px;
  @media (max-width: 575px) {
    padding: 2px 5px 5px;
  }
`;

export const Li = styled.li`
  display: flex;
  ${fontStyles[16]}
  color: var(--color-black);
  &::before {
    content: '';
    width: 10px;
    min-width: 10px;
    height: 10px;
    border: 1px solid var(--color-black);
    border-radius: 50%;
    margin: 0.25em 3px 0 0;
  }
  @media (max-width: 849px) {
    ${fontStyles[18]}
  }
`;

export const A = styled.a`
  ${fontStyles[16]}
  color: var(--color-black);
  @media (max-width: 849px) {
    ${fontStyles[18]}
  }
`;

export const Link = styled(DefLink)`
  min-height: 42px;
  display: grid;
  grid-template-columns: 330px 1fr auto;
  column-gap: 4px;
  align-items: center;
  border: solid var(--color-black);
  border-width: 1px 0;
  padding: 2px 10px;
  text-decoration: none;
  @media (max-width: 849px) {
    min-height: 60px;
    grid-template-columns: 370px 1fr auto;
  }
  @media (max-width: 575px) {
    padding: 2px 5px;
  }
  @media (max-width: 505px) {
    grid-template-columns: 7fr 1fr auto;
  }
`;

export const LinkText = styled.p`
  ${fontStyles[16]}
  color: var(--color-black);
  @media (max-width: 849px) {
    ${fontStyles[18]}
  }
`;

export const LinkDotsWrap = styled.div`
  width: 35px;
  height: 35px;
  transition: width 0.5s ease-in;
  will-change: width;
  position: relative;
  @media (hover) and (min-width: 850px) {
    ${Link}:hover > & {
      width: 125px;
    }
  }
  @media (max-width: 849px) {
    width: 56px;
    height: 56px;
  }
`;

export const LinkDot = styled.div`
  width: 35px;
  height: 35px;
  border: 1px solid var(--color-blue);
  border-radius: 50%;
  background-color: var(--color-blue);
  position: absolute;
  top: 0;
  left: ${({ i }) => i && `calc(50% * ${i})`};
  transform: ${({ i }) => i && `translateX(calc(-50% * ${i}))`};
  transition-property: border-color, background-color;
  transition-duration: 0.3s;
  @media (hover) {
    &:hover {
      border-color: var(--color-black);
      background-color: var(--color-black);
    }
    ${Link}:active & {
      border-color: var(--color-black);
      background-color: var(--color-white);
    }
  }
  @media (max-width: 849px) {
    width: 56px;
    height: 56px;
  }
`;
