import React, { Fragment } from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import { threeDots } from '@/utils';

import ReviewList from './ReviewList';
import {
  TitleWrap,
  TitleDot,
  Title,
  Text,
  AppsListWrap,
  AppsListItem,
  LinksWrap,
  Link,
  LinkDotsWrap,
  LinkDot,
  LinkText,
  Arrow,
} from './styles';

const App = () => {
  const { contentJson } = useStaticQuery(graphql`
    {
      contentJson {
        app {
          items {
            id
            links {
              name
              href
            }
            title
            text
            list
          }
        }
      }
    }
  `);
  const { items } = contentJson.app;

  return items.map(({ id, title, text, list, links }) => (
    <Fragment key={id}>
      <TitleWrap>
        <TitleDot />
        <Arrow right />
        <Title>{title}</Title>
        <Arrow left />
        <TitleDot />
      </TitleWrap>
      {id === 'text' && text && <Text>{text}</Text>}
      {id === 'apps' && list && (
        <AppsListWrap>
          {list.map((item) => (
            <AppsListItem key={item}>{item}</AppsListItem>
          ))}
        </AppsListWrap>
      )}
      {id === 'apps' && links && (
        <LinksWrap>
          {links.map(({ href, name }) => (
            <Link key={href} href={href} rel="noopener noreferrer" target="_blank">
              <LinkDotsWrap>
                {threeDots.map((i) => (
                  <LinkDot key={i} i={i} />
                ))}
              </LinkDotsWrap>
              <LinkText>{name}</LinkText>
            </Link>
          ))}
        </LinksWrap>
      )}
      {id === 'review' && list && <ReviewList list={list} />}
    </Fragment>
  ));
};

export default App;
