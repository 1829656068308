import styled from 'styled-components';

import { fontStyles } from '@/theme';

import DefArrow from '@/components/Arrow';

export const TitleWrap = styled.div`
  display: grid;
  grid-template-columns: 21px 1fr auto 1fr 21px;
  column-gap: 5px;
  align-items: center;
  min-height: 29px;
  border: 1px solid var(--color-black);
  border-width: 1px 0;
  padding: 0 8px;
  @media (max-width: 849px) {
    grid-template-columns: 39px 67px auto;
    min-height: 46px;
  }
  @media (max-width: 575px) {
    padding: 0 5px;
    column-gap: 2px;
  }
`;

export const TitleDot = styled.div`
  width: 21px;
  height: 21px;
  border-radius: 50%;
  background-color: var(--color-blue);
  margin-top: 1px;
  transition: background-color 0.3s;
  @media (hover) {
    &:hover {
      background-color: var(--color-black);
    }
  }
  @media (max-width: 849px) {
    width: 39px;
    height: 39px;
    margin-top: 0;
    &:last-of-type {
      display: none;
    }
  }
`;

export const Title = styled.h2`
  ${fontStyles[16]}
  color: var(--color-black);
  @media (max-width: 849px) {
    ${fontStyles[18]}
  }
`;

export const Text = styled.p`
  ${fontStyles[30]}
  color: var(--color-black);
  padding: 4px 10px 20px;
  @media (max-width: 849px) {
    ${fontStyles[24]}
  }
  @media (max-width: 575px) {
    padding: 0 5px 27px;
  }
`;

export const AppsListWrap = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media (max-width: 849px) {
    grid-template-columns: 1fr;
  }
`;

export const AppsListItem = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 27px;
  border-bottom: 1px solid var(--color-black);
  ${fontStyles[16]}
  color: var(--color-black);
  padding: 0 8px;
  &::before {
    content: '';
    width: 21px;
    min-width: 21px;
    height: 21px;
    border-radius: 50%;
    background-color: var(--color-blue);
    margin-right: 3px;
    transition: background-color 0.3s;
  }
  @media (hover) {
    &:hover {
      &::before {
        background-color: var(--color-black);
      }
    }
  }
  @media (max-width: 849px) {
    min-height: 46px;
    ${fontStyles[18]}
    &::before {
      content: '';
      width: 39px;
      min-width: 39px;
      height: 39px;
      border-radius: 50%;
      background-color: var(--color-blue);
      margin-right: 5px;
    }
  }
  @media (max-width: 575px) {
    padding: 0 5px;
  }
`;

export const LinksWrap = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 27px 10px;
  border: 1px solid var(--color-black);
  @media (max-width: 849px) {
    margin: 33px 5px;
  }
  @media (max-width: 575px) {
    margin: 33px 0;
  }
`;

export const Link = styled.a`
  display: block;
  width: 100%;
  text-decoration: none;
  &:first-of-type {
    border-right: 1px solid var(--color-black);
  }
`;

export const LinkDotsWrap = styled.div`
  width: 130px;
  height: 144px;
  transition: width 0.5s ease-in;
  will-change: width;
  position: relative;
  @media (hover) {
    ${Link}:hover > & {
      width: calc(100% - 14px);
    }
  }
  @media (max-width: 849px) {
    width: 56px;
    height: 70px;
  }
`;

export const LinkDot = styled.div`
  width: 130px;
  height: 130px;
  border: 1px solid var(--color-blue);
  border-radius: 50%;
  background-color: var(--color-blue);
  position: absolute;
  top: 7px;
  left: ${({ i }) => (i ? `calc(50% * ${i} + 7px)` : '7px')};
  transform: ${({ i }) => i && `translateX(calc(-50% * ${i}))`};
  transition-property: border-color, background-color;
  transition-duration: 0.3s;
  @media (hover) {
    &:hover {
      border-color: var(--color-black);
      background-color: var(--color-black);
    }
    ${Link}:active & {
      border-color: var(--color-black);
      background-color: var(--color-white);
    }
  }
  @media (max-width: 849px) {
    width: 56px;
    height: 56px;
  }
`;

export const LinkText = styled.p`
  ${fontStyles[30]}
  line-height: 34px;
  color: var(--color-black);
  border-top: 1px solid var(--color-black);
  padding: 0 5px;
  @media (max-width: 849px) {
    ${fontStyles[18]}
    padding: 8px 10px;
  }
`;

export const Arrow = styled(DefArrow)`
  @media (max-width: 849px) {
    ${({ left }) => left && 'display: none'}
  }
`;
