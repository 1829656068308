import { useCallback } from 'react';

import { useAsyncState } from '@/utils';

export const useSubmitForm = (successMessage, errorMessage) => {
  const [state, setState] = useAsyncState({});

  const action = useCallback(
    async (args) => {
      setState({ loading: true });

      try {
        const response = await fetch(`${process.env.GATSBY_API_URL}/contacts`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'api-key': process.env.GATSBY_API_TOKEN,
          },
          body: JSON.stringify(args),
        });

        if (response.status < 200 || response.status >= 300) throw Error(errorMessage);

        setState({ success: successMessage });
      } catch (e) {
        setState({ error: errorMessage });
      }
    },
    [setState, successMessage, errorMessage]
  );

  const reset = useCallback(() => setState({}), [setState]);

  return [state, action, reset];
};
