import React from 'react';
import PropTypes from 'prop-types';

import { Container, Line, Wedge } from './styles';

const Arrow = ({ className, right, left }) => (
  <Container className={className}>
    <Line />
    <Wedge $right={right} $left={left} />
  </Container>
);

Arrow.defaultProps = {
  className: '',
  right: false,
  left: false,
};
Arrow.propTypes = {
  className: PropTypes.string,
  right: PropTypes.bool,
  left: PropTypes.bool,
};

export default Arrow;
