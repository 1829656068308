import styled from 'styled-components';

import { fontStyles } from '@/theme';

export const Button = styled.button`
  width: 100%;
  ${fontStyles[98]}
  line-height: 0.8;
  text-align: left;
  overflow-wrap: break-word;
  hyphens: auto;
  border-bottom: 1px solid var(--color-black);
  break-after: avoid;
  break-inside: avoid;
  padding-bottom: 20px;
  margin-top: ${({ mt }) => (mt ? 35 : 0)}px;
  @media (max-width: 849px) {
    ${fontStyles[77]}
    line-height: 0.8;
    margin-top: ${({ mt }) => (mt ? 20 : 0)}px;
  }
`;

export const Content = styled.div`
  max-height: ${() => 5000}px;
  transition: max-height 0s;
  @media (max-width: 720px) {
    max-height: ${({ isOpen }) => (isOpen ? 5000 : 0)}px;
    transition: max-height 0.5s;
    overflow: hidden;
  }
`;
