import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { threeDots } from '@/utils';
import Arrow from '@/components/Arrow';

import { Container, LinkText, HighlightLinkText, DotsWrap, Dot } from './styles';

const Link = ({ text, href }) => {
  const texts = useMemo(() => text.split('##link##'), [text]);

  return (
    <Container href={href} rel="noopener noreferrer" target="_blank">
      <LinkText>
        {texts[0]}
        <HighlightLinkText>{texts[1]}</HighlightLinkText>
        {texts[2]}
      </LinkText>
      <Arrow right />
      <DotsWrap>
        {threeDots.map((i) => (
          <Dot key={i} i={i} />
        ))}
      </DotsWrap>
    </Container>
  );
};

Link.propTypes = {
  text: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
};

export default Link;
