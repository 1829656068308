import React from 'react';
import PropTypes from 'prop-types';

import { Container, Head, SideTop, SideBottom } from './styles';

const Packman = ({ right, left }) => (
  <Container $right={right} $left={left}>
    <Head>
      <SideTop $right={right} $left={left} />
      <SideBottom $right={right} $left={left} />
    </Head>
  </Container>
);

Packman.defaultProps = { right: false, left: false };
Packman.propTypes = { right: PropTypes.bool, left: PropTypes.bool };

export default Packman;
