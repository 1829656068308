import React, { useRef, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

import { A } from './styles';

const PageAnchor = ({ id, hash }) => {
  const ref = useRef();

  const handleScroll = useCallback(() => {
    const header = document.querySelector('header');

    if (!(ref.current?.offsetTop || header)) return;

    const top = Math.max(0, ref.current.offsetTop - (header.getBoundingClientRect()?.height || 0) + 1);

    try {
      window.scrollTo({
        top,
        behavior: 'smooth',
      });
    } catch (e) {
      console.error(e); // eslint-disable-line no-console
      window.scrollTop = top;
    }
  }, []);

  useEffect(() => {
    if (typeof window === 'undefined' || !hash || id !== hash) return () => null;

    const frame = window.requestAnimationFrame(handleScroll);
    window.addEventListener('hashchange', handleScroll);

    return () => {
      window.removeEventListener('hashchange', handleScroll);
      window.cancelAnimationFrame(frame);
    };
  }, [handleScroll, hash, id]);

  return <A ref={ref} to={id} aria-label={id} />;
};

PageAnchor.propTypes = {
  id: PropTypes.string.isRequired,
  hash: PropTypes.string.isRequired,
};

export default PageAnchor;
